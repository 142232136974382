import * as RadixTooltip from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import { useState } from 'react';

export interface TooltipProps extends RadixTooltip.TooltipContentProps {
  content: string;
  className?: string;
}

export const Tooltip = ({
  className,
  side = 'bottom',
  align = 'center',
  sideOffset = 4,
  children,
  content,
  ...props
}: TooltipProps) => {
  const [open, setOpen] = useState(false);

  return (
    <RadixTooltip.Provider delayDuration={200}>
      <RadixTooltip.Root open={open}>
        <RadixTooltip.Trigger
          onClick={() => setOpen(true)}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          {children}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            side={side}
            align={align}
            sideOffset={sideOffset}
            {...props}
            className={clsx(
              'z-50 rounded-md bg-gray-700 text-white px-3 py-1.5 text-sm shadow-md max-w-[200px] relative',
              'animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
              className,
            )}
          >
            {content}
            <RadixTooltip.Arrow className="fill-gray-700" />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
