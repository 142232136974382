import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

type CardLabelVariants = 'stock' | 'product-state';

const variantStyles = (variant: CardLabelVariants): string => {
  switch (variant) {
    case 'product-state':
      return 'border';
    default:
      return '';
  }
};

export interface CardLabelProps {
  className?: string;
  variant?: CardLabelVariants;
}

export const CardLabel: FC<PropsWithChildren<CardLabelProps>> = ({
  className,
  variant = 'product-state',
  ...props
}) => (
  <span
    className={clsx(
      'card__label text-sm rounded-full pr-2.5 py-1 text-primary-900 no-underline',
      variantStyles(variant),
      className,
    )}
    {...props}
  />
);
